import DbLog from './dbLog'

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function () {
  let hash = 0; let i; let chr
  if (this.length === 0) { return hash }
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  if (hash < 0) {
    hash = hash * -1
  }
  return hash
}

export const isWindow = (() => {
  return (typeof window === 'object')
})()

export const isWorker = (() => {
  return (typeof importScripts === 'function')
})()

export const isServer = (() => {
  return (!isWindow && !isWorker && typeof process === 'object' && typeof require === 'function')
})()

export const randId = function (length = 8) {
  // TODO: replace in codebase
  return Math.random().toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, length)
}

export const responceCodeVariant = (code) => {
  let variant = ''
  if ([200, 201, 204].includes(code)) {
    variant = 'success'
  } else if ([403, 422, 423].includes(code)) {
    variant = 'warning'
  } else /* if ([0, 404, 500].includes(code)) */ {
    variant = 'danger'
  }
  return variant
}

export const responceCodeIcon = (code) => {
  let icon
  if ([200, 201, 204].includes(code)) {
    icon = ['fas', 'check']
  } else if ([403, 422].includes(code)) {
    icon = ['fas', 'triangle-exclamation']
  } else /* if ([0, 404, 500].includes(code)) */ {
    icon = ['fas', 'circle-xmark']
  }
  return icon
}

/**
 * Debug Helpers
 * */
let dblog
function isDbOpen () {
  const APP_DEBUG = process.env.APP_DEBUG || false
  if ((isWindow || isWorker) && APP_DEBUG) {
    if (!dblog) {
      try {
        dblog = new DbLog(process.env.TAB_ID || undefined)
        return true
      } catch (e) {
        console.error('Init DB', e)
      }
    } else {
      return true
    }
  }
  return false
}
function isDebug () {
  return process.env.APP_DEBUG || false
}

export function getTabKeys () {
  if (isDbOpen()) {
    return dblog.getTabKeys()
  }
  return null
}

export function getLog (props) {
  if (isDbOpen()) {
    return dblog.getLog(props)
  }
  return null
}

export function clearLog () {
  if (isDbOpen()) {
    return dblog.clearLog()
  }
  return null
}

export function clearTabLog (tabKey) {
  if (isDbOpen()) {
    return dblog.clearTabLog(tabKey)
  }
  return null
}

export function log (...args) {
  if (isDebug()) {
    console.groupCollapsed(...args)
    console.trace()
    console.groupEnd()
    if (isDbOpen()) {
      dblog.log(
        process.env.TAB_ID ? 'tab' : 'wkr',
        process.env.TAB_ID || '',
        new Date(),
        ...args
      )
    }
  }
}

export function info (...args) {
  if (isDebug()) {
    console.info(...args)
    if (isDbOpen()) {
      dblog.info(
        process.env.TAB_ID ? 'tab' : 'wkr',
        process.env.TAB_ID || '',
        new Date(),
        ...args
      )
    }
  }
}

export function warn (...args) {
  if (isDebug()) {
    console.warn(...args)
    if (isDbOpen()) {
      dblog.warn(
        process.env.TAB_ID ? 'tab' : 'wkr',
        process.env.TAB_ID || '',
        new Date(),
        ...args
      )
    }
  }
}

export function error (...args) {
  console.error(...args)
  if (isDbOpen()) {
    dblog.error(
      process.env.TAB_ID ? 'tab' : 'wkr',
      process.env.TAB_ID || '',
      new Date(),
      ...args
    )
  }
}
