import { hwChannelHandler } from './channelHandlers/hw-channel-handler'
import { boChannelHandler } from './channelHandlers/bo-channel-handler'
import { repoChannelHandler } from './channelHandlers/repo-channel-handler'
import { chatChannelHandler } from './channelHandlers/chat-channel-handler'
import { log, warn } from '~/mixins/helpers'

export default function channelHandler ({ $auth, $store, $repo, responce }) {
  log('[ChannelHandler][responce]', responce)

  let channelName = responce?.channel
  const ms = channelName.match(/^bo-lock\.([\w]*)\.([\d]*)/)
  if (ms) {
    channelName = 'bo-lock'
  }
  switch (channelName) {
    case 'hw':
      hwChannelHandler({ $store, responce })
      break
    case 'bo':
      boChannelHandler({ $auth, $store, $repo, responce })
      break
    case `repo.${$auth.user.userId}`:
      repoChannelHandler({ $auth, $store, responce })
      break
    case 'chat.admin':
      chatChannelHandler({ $auth, $store, responce })
      break
    case 'bo-lock':
      // log('[LOC-CHANEL]', { repo: ms[1], id: ms[2], responce })
      break
    default:
      warn('Unknown channel', responce?.channel)
      break
  }
}
