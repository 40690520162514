import { warn } from '~/mixins/helpers'

export async function repoChannelHandler ({ $auth, $store, responce }) {
  const handler = new RepoChannelHandler($auth, $store, responce)

  switch (responce?.action) {
    case 'App\\Events\\Backoffice\\BackofficeRepo':
      await handler.backofficeRepo()
      break
    default:
      warn('Unknown `repo` channel action', responce?.action)
      break
  }
}

class RepoChannelHandler {
  auth = null
  store = null
  responce = null

  constructor (auth, store, responce) {
    this.auth = auth
    this.store = store
    this.responce = responce
    // get chat methods
    this.getChatOpen = store.getters['chat/getChatOpen']
    this.isChatInTalksList = store.getters['chat/isChatInTalksList']
    this.isChatUnread = store.getters['chat/isChatUnread']
  }

  async backofficeRepo () {
    const e = this.responce?.event
    // console.log('event', e)

    // dispatch array
    const calls = []

    // else if (
    //   e.repo === 'tasks' &&
    //   e.data.status === 'open' &&
    //   e.data.type === 'check-support' &&
    //   e.data.data.receiver_id === this.auth.user.userId
    // ) {
    //   // If "support chat" task where updated
    //   calls.push(['chat/loadTalks', { talkId: e.data.data.talk_id }])
    // }
    if (e.repo === 'users' && this.auth.user.userId === (e?.data?.id || e?.data?.data?.id)) {
      // If current staff user record where updated
      calls.push(['staffUser/selfReload'])
      //
    } else if (
      e?.repo === 'support_talk'
      // (
      //   e?.data?.staff?.id === this.auth.user.userId ||
      //   this.isChatInTalksList(e.data.id) ||
      //   this.getChatOpen(e.data.talk_id)
      // )
    ) {
      // If current staff member assigned to talk and talk has been updated
      calls.push(['chat/updateTalksList', e.data])
      if (
        // e?.data?.staff?.id === this.auth.user.userId &&
        !this.isChatInTalksList(e.data.id)
      ) {
        calls.push(['chat/loadTalks', { talkId: e.data.id }])
      }
      //
    } else if (e?.repo === 'support_message') {
      if (this.isChatInTalksList(e.data.talk_id)) {
        calls.push(['chat/updateMessageList', e.data])
      }
      // If the current employee who has a chat room open and messages have been updated
      if (this.getChatOpen(e.data.talk_id) && this.isChatUnread(e.data.talk_id)) {
        calls.push(['chat/read', e.data.talk_id])
      }
      // console.log('[isChatInTalksList]', this.isChatInTalksList(e.data.talk_id), this.getChatOpen(e.data.talk_id))
      //
    } else if (e.repo === 'customers') {
      // if customer profile updated
      calls.push(['sync/customer', e.data.id])
    }

    // Reload repository data if it has already been fetched
    calls.push(['repos/remoteUpdate', { repo: e.repo, data: e.data }])

    // console.log('CALS', calls)

    // Dispatch background updates without "loading progress" indication
    await this.store.dispatch('setLoadingEnable', false, { root: true })
    await Promise.all(calls.map(async (args) => {
      await this.store.dispatch(...args)
    }))
    await this.store.dispatch('setLoadingEnable', true, { root: true })
  }
}
