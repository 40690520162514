import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { isServer } from '@/mixins/helpers'

export const getFormInitState = (formFields) => {
  const form = {}
  Object.keys(formFields).forEach((key) => {
    const field = formFields[key]
    if (field.default) {
      form[key] = field.default
    } else {
      switch (field.type) {
        case Object:
          form[key] = {}
          break
        case Array:
        case Int8Array:
        case Int16Array:
        case Int32Array:
        case BigInt64Array:
        case Uint8Array:
        case Uint16Array:
        case Uint32Array:
        case BigUint64Array:
        case Float32Array:
        case Float64Array:
          form[key] = []
          break
        case Boolean:
          form[key] = false
          break
        case Number:
          form[key] = 0
          break
        case String:
        default:
          form[key] = ''
          break
      }
    }
  })
  return form
}

export const isDirty = (type, initialValue, actualValue) => {
  let result
  const arrayEquals = (a, b) => {
    if (!a || !b || a.length !== b.length) { return false }
    const uniqueValues = new Set([...a, ...b])
    for (const v of uniqueValues) {
      const aCount = a.filter(e => e === v).length
      const bCount = b.filter(e => e === v).length
      if (aCount !== bCount) { return false }
    }
    return true
  }
  switch (type) {
    case Object:
      result = JSON.stringify(initialValue) !== JSON.stringify(actualValue)
      break
    case Array:
    case Int8Array:
    case Int16Array:
    case Int32Array:
    case BigInt64Array:
    case Uint8Array:
    case Uint16Array:
    case Uint32Array:
    case BigUint64Array:
    case Float32Array:
    case Float64Array:
      result = !arrayEquals(initialValue, actualValue)
      break
    case Boolean:
      if (initialValue === '') {
        initialValue = false
      }
      if (actualValue === '') {
        actualValue = false
      }
      result = initialValue !== actualValue
      break
    case Number:
      if (initialValue === '') {
        initialValue = 0
      }
      if (actualValue === '') {
        actualValue = 0
      }
      result = parseFloat(initialValue) !== parseFloat(actualValue)
      break
    case String:
    default:
      result = `${initialValue}` !== `${actualValue}`
      break
  }
  return result
}

export const mapInitFields = (formName, fields) => {
  const res = {
    dirtyStored: `forms.${formName}.dirty`,
    oldForm: `forms.${formName}.oldForm`
  }
  if (fields && fields.length) {
    fields.forEach((field) => {
      res[field] = `forms.${formName}.form.${field}`
    })
  }
  return res
}

export const mapComputedFormFields = (formName, fields) => {
  const res = {}

  res._key = {
    get (key = null, value = null) {
      const v = []
      for (const k of fields) {
        if (key && key === k) {
          v.push(`${k}:${value}`)
        } else if (this.dirty && Object.keys(this.dirty).includes(k)) {
          v.push(`${k}:${this.dirty[k]}`)
        } else {
          v.push(`${k}:${this[k]}`)
        }
      }
      return v.join('&').hashCode()
    },
    set (value) {
      const keys = JSON.parse(JSON.stringify(this.keys))
      keys[this.$options.name] = value

      this.$store.dispatch(`forms/${formName}/storeStateField`, {
        itemId: this.itemId,
        fieldName: 'keys',
        value: keys
      })
    }
  }

  for (const fieldName of fields) {
    res[fieldName] = {
      get () {
        const item = this.$store.state.forms[formName] &&
          this.$store.state.forms[formName].forms &&
          this.$store.state.forms[formName].forms.find(i => i.id === this.itemId)
        return item && item.form && item.form[fieldName]
      },
      set (value) {
        this.$store.dispatch(`forms/${formName}/storeFormField`, {
          itemId: this.itemId,
          fieldName,
          value,
          silent: false
        })
        this._key = res._key.get(fieldName, value)
      }
    }
  }

  return res
}

export const mapComputedStateFields = (formName, fields) => {
  let allFields = ['key', 'keys', 'isFetched']
  if (fields && fields.length) {
    allFields = allFields.concat(fields)
  }
  const res = {}
  for (const fieldName of allFields) {
    res[fieldName] = {
      get () {
        const item = this.$store.state.forms[formName] &&
          this.$store.state.forms[formName].forms &&
          this.$store.state.forms[formName].forms.find(i => i.id === this.itemId)
        return item && item[fieldName]
      },
      set (value) {
        this.$store.dispatch(`forms/${formName}/storeStateField`, {
          itemId: this.itemId,
          fieldName,
          value
        })
      }
    }
  }
  return res
}

export const GlobalForm = (formName, formFields = {}) => {
  if (!formName) {
    throw new Error('Form name missing')
  }
  return {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      repo: {
        type: String,
        required: true,
        default: ''
      },
      itemId: {
        type: Number,
        default: null
      },
      fields: {
        type: Array,
        required: false,
        default: () => ([])
      },
      idx: {
        type: Number,
        default: -1
      }
    },
    beforeDestroy () {
      if (this.isLockedMy) {
        this.$repo.unlockMyItem(formName, this.repo, this.itemId)
      }
    },
    data () {
      return {
        state: this.value,
        busy: false,
        callsCheckTimer: null
      }
    },
    async fetch (force = false) {
      this.isFetched = false
      this.clearForm()
      if (this.itemId !== null && parseInt(this.itemId) > 0) {
        try {
          this.busy = true
          const fetchedIdx = this.getFetchedIdx(this.repo, this.itemId)
          if (fetchedIdx && !force) {
            // If record already loaded and current idx greater
            if (fetchedIdx !== this.idx) {
              // If just open already opened in new form
              this.cloneFetched({ fromIdx: fetchedIdx, toIdx: this.idx })
            } else {
              // If record updated
              await this.call({
                repo: this.repo,
                method: 'show',
                id: this.itemId,
                idx: this.idx
              })
            }
            this.fillData()
            this.$forceUpdate()
            this.isFetched = true
          } else {
            await this.call({
              repo: this.repo,
              method: 'edit',
              id: this.itemId,
              idx: this.idx
            })
            await this.call({
              repo: this.repo,
              method: 'show',
              id: this.itemId,
              idx: this.idx
            })
            this.$nextTick(() => {
              this.fillData()
              this.$forceUpdate()
              this.isFetched = true
            })
          }
          if (this.isLocked) {
            // if record locked by other
            this.flushDirty(this.itemId)
          }
          this.$nextTick(() => {
            this.$log(`[FORM][${formName}] is fetched`, { id: this.itemId })
          })
        } catch (e) {
          if (process.env.NODE_ENV === 'development') {
            this.$error(`[FORM][${formName}] fetch error: `, e)
          }
        } finally {
          this.busy = false
        }
      }
      // If added new calls from whisper TODO: remove on will moved at worker
      if (this.callsCheckTimer !== null) {
        clearInterval(this.callsCheckTimer)
      }
      this.callsCheckTimer = setInterval(async () => {
        if (this.isFetched && this.callsCount > 0) {
          this.isFetched = false
          this.clearForm()
          await this.releaseCalls()
          this.fillData()
          this.flushDirty({ itemId: this.itemId })
          this.$forceUpdate()
          this.isFetched = true
        }
      }, 1000)
    },
    computed: {
      ...mapGetters({
        getByName: 'repos/getByName',
        getFetchedIdx: 'repos/getFetchedIdx',
        errors: 'repos/errors',
        lastIdx: 'repos/lastIdx',
        totalLockedRepo: 'shared/totalLocked',
        isLockedRepo: 'shared/isLocked',
        isLockedMyRepo: 'shared/isLockedMy',
        isLockedByRepo: 'shared/isLockedBy',
        isLockedByMeRepo: 'shared/isLockedByMe',
        callsCount: 'repos/callsCount',
        deviceInfo: 'device'
      }),
      ...mapGetters({
        getFromChain: 'repos/getFromChain'
      }),
      ...mapFields({
        keys: `forms.${formName}.keys`
      }),
      ...mapComputedFormFields(formName, Object.keys(formFields)),
      ...mapComputedStateFields(formName),
      formDataRepo () {
        if (this.itemId !== null && parseInt(this.itemId) > 0) {
          return this.getByName(`${this.repo}/edit`, this.idx) || false
        }
        return false
      },
      itemRepo () {
        if (this.itemId !== null && parseInt(this.itemId) > 0) {
          const value = this.getByName(`${this.repo}/show`, this.idx)
          return value && value.data
        }
        return false
      },
      form () {
        const form = this.$store.state.forms[formName] && this.$store.state.forms[formName].forms.find(i => i.id === this.itemId)
        return form && form.form
      },
      oldForm () {
        const form = this.$store.state.forms[formName] && this.$store.state.forms[formName].forms.find(i => i.id === this.itemId)
        return form && form.oldForm
      },
      dirty () {
        const form = this.$store.state.forms[formName] && this.$store.state.forms[formName].forms.find(i => i.id === this.itemId)
        return form && form.dirty
      },
      dirtyKey () {
        const form = this.$store.state.forms[formName] && this.$store.state.forms[formName].forms.find(i => i.id === this.itemId)
        return form && form.dirtyKey
      },
      isMobile () {
        return this.deviceInfo?.type === 'mobile' || false
      },
      isTablet () {
        return this.deviceInfo?.type === 'tablet' || false
      },
      width () {
        if (isServer) {
          return 0
        } else if (this.isMobile) {
          return document.body.offsetWidth
        } else if (this.isTablet) {
          return Math.round(document.body.offsetWidth * 0.90) - (15 * this.idx - 1)
        }
        return Math.round(document.body.offsetWidth * 0.80) - (15 * this.idx - 1)
      },
      displayTitle () {
        let title
        if (this.title) {
          title = this.title
        } else {
          title = `${
            this.itemId !== null
              ? this.$t('doc.ItemForm.labelEdit')
              : this.$t('doc.ItemForm.labelNew')
          } ${this.$t('doc.ItemForm.labelItem')} ${this.itemId !== null ? ` - ID:${this.itemId}` : ''}`
        }
        return title
      },
      isLocked () {
        return this.isLockedRepo(this.repo, this.itemId)
      },
      isLockedBy () {
        return this.isLockedByRepo(this.repo, this.itemId)
      },
      isLockedMy () {
        return this.isLockedMyRepo(this.repo, this.itemId)
      },
      isLockedByMe () {
        return this.isLockedByMeRepo(this.repo, this.itemId)
      },
      isNotFound () {
        return this.errors?.length > 0 &&
          this.errors?.filter(e => (e.code === 403 || e.code === 404) && e.method === 'show' && e.repository === this.repo).length > 0
      },
      formErrors () {
        return this.errors.filter((error) => {
          return [
            'show',
            'create',
            'edit',
            'store',
            'update'
          ].includes(error.method)
        })
      }
    },
    watch: {
      value (n) {
        this.state = !!n
        if (!this.state) {
          this.flushData()
        }
        this.$forceUpdate()
      },
      itemId (n, o) {
        if (!this.isLocked) {
          if (!n && o && !this.state && this.isLockedMy) {
            this.$repo.unlockMyItem(formName, this.repo, o)
          }
        }
      },
      lastIdx (n, o) {
        this.$nextTick(() => {
          if (n === this.idx && n < o) {
            this.$fetch()
          }
        })
      },
      itemRepo: {
        handler () {
          // if item where updated in background
          if (this.isFetched) {
            this.fillData()
          }
        },
        deep: true
      },
      dirtyKey (n, o) {
        // Lock/Unlock trigger
        if (n === 0) {
          this.$warn('[TAB][SHOULD_UNLOCK]', { isLockedMy: this.isLockedMy })
          // skipped or init
          if (this.isLockedMy) {
            this.$repo.unlockMyItem(formName, this.repo, this.itemId)
            // flush dirty!?
          }
        } else if (n !== 0) {
          // changed or init
          if (!this.isLocked && !this.isLockedMy) {
            this.$repo.lockMyItem(formName, this.repo, this.itemId)
          }
        }
      },
      isLocked (n) {
        if (n) {
          // indicate favicon
          this.setLayoutWindowIcon('favicon-dead')
        } else {
          // regular favicon
          this.setLayoutWindowIcon('favicon')
        }
      },
      isLockedMy (n) {
        if (n) {
          // indicate favicon
          this.setLayoutWindowIcon('favicon-alert')
        } else {
          // regular favicon
          this.setLayoutWindowIcon('favicon')
        }
      }
    },
    methods: {
      ...mapActions({
        call: 'repos/call',
        releaseCalls: 'repos/releaseCalls',
        cloneFetched: 'repos/cloneFetched',
        refreshList: 'repos/refreshList',
        flush: 'repos/flush',
        flushError: 'repos/flushError',
        clearForm: `forms/${formName}/clearForm`,
        removeForm: `forms/${formName}/removeForm`,
        applyForm: `forms/${formName}/applyForm`,
        flushDirty: `forms/${formName}/flushDirty`,
        storeFormField: `forms/${formName}/storeFormField`,
        setLayoutPageTitle: 'layout/setPageTitle',
        setLayoutWindowTitle: 'layout/setWindowTitle',
        setLayoutWindowIcon: 'layout/setWindowIcon'
      }),
      flushData () {
        this.flush({
          repo: this.repo,
          method: 'edit',
          id: this.itemId,
          idx: this.idx
        })
        this.flush({
          repo: this.repo,
          method: 'show',
          id: this.itemId,
          idx: this.idx
        })
        const existChainItem = this.getFromChain({ key: `${this.$options.name}.${this.itemId}` })
        if (existChainItem && existChainItem.length === 1) {
          // remove form from list
          this.removeForm(this.itemId)
        }
      },
      isDirty (fieldKey) {
        const field = formFields[fieldKey]
        if (field) {
          return isDirty(field.type, this.oldForm[fieldKey], this[fieldKey])
        }
        return false
      },
      // fillData () { // Example
      //   if (this.itemRepo) {
      //     // fill oldForm & storage fields...
      //   }
      //   if (this.dirtyStored && Object.keys(this.dirtyStored).length) {
      //     Object.keys(this.dirtyStored).forEach((k) => {
      //       this[k] = this.dirtyStored[k]
      //     })
      //   }
      // },
      onReload () {
        this.flushError()
        this.$fetch()
      },
      onCancel () {
        if (this.dirty && Object.keys(this.dirty).length) {
          this.$confirmWarning({
            title: this.$t('eDoc_list_changedConfirmTitle'),
            body: this.$t('eDoc_list_changedConfirmBody'),
            okTitle: this.$t('eDoc_list_changedConfirmOk'),
            cancelTitle: this.$t('eDoc_list_changedConfirmCancel')
          })
            .then((value) => {
              if (value) {
                this.flushDirty({ itemId: this.itemId })
                this.flushError()
                this.state = false
                // await slider transition
                setTimeout(() => {
                  this.flushData()
                  this.$emit('on-cancel')
                }, 300)
              }
            })
        } else {
          this.flushError()
          this.state = false
          // await slider transition
          setTimeout(() => {
            this.flushData()
            this.$emit('on-cancel')
          }, 300)
        }
      },
      async onSubmit () {
        this.flushError()
        if (this.itemRepo && this.itemRepo.id) {
          try {
            this.busy = true
            await this.call({
              repo: this.repo,
              method: 'update',
              id: this.itemRepo.id,
              payload: this.dirty
            })
          } catch (e) {} finally {
            this.busy = false
          }
        }
        if (!this.errors || this.errors.length === 0) {
          // reload form
          await this.$fetch()
          this.fillData()
          // share apply form
          this.$repo.applyForm(formName, this.itemRepo.id, this.form)
          this.applyForm({ itemId: this.itemId, formData: this.form })
          // Unlock item
          if (this.isLockedMy) {
            this.$repo.unlockMyItem(formName, this.repo, this.itemId)
          }
          // // Whisp to reload form
          this.$repo.reloadItem(formName, this.repo, this.itemRepo.id)
          // bulk event
          this.$emit('on-submit', { close: false })
        }
      },
      async onSubmitClose () {
        this.flushError()
        if (this.itemRepo && this.itemRepo.id) {
          try {
            this.busy = true
            await this.call({
              repo: this.repo,
              method: 'update',
              id: this.itemRepo.id,
              payload: this.dirty
            })
          } catch (e) {} finally {
            this.busy = false
          }
        }
        this.$log(`[FORM][${formName}][onSubmitClose]`, this.errors)
        if (!this.errors || this.errors.length === 0) {
          // reload form
          await this.$fetch()
          this.fillData()
          // share apply form
          this.$repo.applyForm(formName, this.itemRepo.id, this.form)
          this.applyForm({ itemId: this.itemId, formData: this.form })
          // Unlock item
          if (this.isLockedMy) {
            this.$repo.unlockMyItem(formName, this.repo, this.itemId)
          }
          // // Whisp to reload form
          this.$repo.reloadItem(formName, this.repo, this.itemRepo.id)
          this.$nextTick(() => {
            // Hide form
            // this.state = false
            // await slider transition
            setTimeout(() => {
              // this.flushData()
              this.$emit('on-submit', { close: false }) // true
            }, 300)
          })
        }
      },
      onDelete () {
        this.$confirmDanger({
          title: this.$t('eDoc_list_deleteConfirmTitle'),
          body: this.$t('eDoc_list_deleteConfirmBody', { id: this.itemRepo.id }),
          okTitle: this.$t('eDoc_list_deleteConfirmOk'),
          cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
        })
          .then(async (value) => {
            if (value) {
              this.flushError()
              this.state = false
              try {
                this.busy = true
                await this.call({
                  repo: this.repo,
                  method: 'delete',
                  id: this.itemRepo.id
                })
                // TODO: $repo.unlock ??
              } catch (e) {} finally {
                this.busy = false
              }
              // await slider transition
              setTimeout(() => {
                this.flushData()
                this.$emit('on-cancel')
              }, 300)
            }
          })
      },
      onClickOutside (e) {
        const skipClassesRegex = new RegExp(['loading-page', 'nav-sidebar', 'b-sidebar', 'modal', 'chat-talk', 'page-link', 'wa-link'].join('|'))
        const path = e?.path || e?.composedPath() || []
        const skipIt = path.filter((p) => {
          return `${p.className}`.match(skipClassesRegex)
        }).length > 0 || false
        if (
          this.dirty &&
            Object.keys(this.dirty).length === 0 &&
            this.idx === this.lastIdx && // close only one last opened form
            !skipIt
        ) {
          this.onCancel()
        }
      },
      onForceUnlockItem () {
        this.$repo.forceUnlockItem(formName, this.repo, this.itemId)
      }
    }
  }
}

export const GlobalFormStore = (storageKey, formFields = {}) => {
  if (!storageKey) {
    throw new Error('Storage key missing')
  }
  return {
    gfActions: {
      fill ({ commit }, { id, data }) {
        commit('fill', { id, data })
      },
      storeFormField ({ commit }, { itemId, fieldName, value, silent }) {
        commit('storeFormField', { itemId, fieldName, value, silent })
      },
      storeStateField ({ commit }, { itemId, fieldName, value }) {
        commit('storeStateField', { itemId, fieldName, value })
      },
      flushDirty ({ commit, dispatch, state }, { itemId }) {
        // whisp open forms
        const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
        if (fetchedIdx >= 0) {
          Object.keys(state.forms[fetchedIdx].dirty).forEach((fieldName) => {
            this.$repo.dirtyItem(storageKey, itemId, fieldName, state.forms[fetchedIdx].oldForm[fieldName], state.forms[fetchedIdx].dirtyKey === 0)
          })
        }
        // flush
        localStorage.removeItem(`${storageKey}.${itemId}`)
        commit('setDirty', { itemId, value: {} })
      },
      clearForm ({ commit }, itemId) {
        commit('clearForm', itemId)
      },
      removeForm ({ commit }, itemId) {
        commit('removeForm', itemId)
      },
      resetForm ({ commit }, itemId) {
        commit('resetForm', itemId)
      },
      async applyForm ({ commit }, { itemId, formData }) {
        await commit('applyForm', { itemId, formData })
        // flush dirty
        localStorage.removeItem(`${storageKey}.${itemId}`)
        commit('setDirty', { itemId, value: {} })
      }
    },
    gfMutations: {
      fill (state, { id, data }) {
      // Overload it
      },
      storeFormField (state, { itemId, fieldName, value, silent }) {
        const isSilent = typeof silent !== 'undefined' && !!silent
        // Store or update data.
        const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
        if (fetchedIdx >= 0) {
          state.forms[fetchedIdx].form[fieldName] = value
          // Update dirty
          const field = formFields[fieldName]
          if (field) {
            const initialValue = state.forms[fetchedIdx].oldForm[fieldName]
            const prevValue = state.forms[fetchedIdx].dirty[fieldName]
            const newValue = state.forms[fetchedIdx].form[fieldName]
            const isFieldDirty = isDirty(field, initialValue, newValue)
            if (isFieldDirty) {
              state.forms[fetchedIdx].dirty[fieldName] = newValue
            } else {
              delete state.forms[fetchedIdx].dirty[fieldName]
            }
            state.forms[fetchedIdx].dirtyKey = Object.keys(state.forms[fetchedIdx].dirty).length
              ? JSON.stringify(state.forms[fetchedIdx].dirty).hashCode()
              : 0
            if (state.forms[fetchedIdx].isFetched) {
              state.key += 1
            }
            if (state.forms[fetchedIdx].dirtyKey === 0) {
              localStorage.removeItem(`${storageKey}.${itemId}`)
            } else {
              localStorage.setItem(`${storageKey}.${itemId}`, JSON.stringify(state.forms[fetchedIdx].dirty))
            }
            if (
              !isSilent &&
              (
                // if prev is initial valye
                (typeof prevValue === 'undefined' && initialValue !== newValue) ||
                // or if prev value not eq new value
                (typeof prevValue !== 'undefined' && prevValue !== newValue)
              )
            ) {
              this.$log(`[STORE][${storageKey}][FIELD]`, { isSilent, fieldName, initialValue, prevValue, newValue })
              this.$repo.dirtyItem(storageKey, itemId, fieldName, value)
            }
          }
        }
      },
      storeStateField (state, { itemId, fieldName, value }) {
      // Store or update data.
        const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
        if (fetchedIdx >= 0) {
          state.forms[fetchedIdx][fieldName] = value
        }
      },
      setPageTitle (state, value) {
        state.pageTitle = value
      },
      setDirty (state, { itemId, value }) {
        const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
        if (fetchedIdx >= 0) {
          state.forms[fetchedIdx].dirty = value
          state.forms[fetchedIdx].dirtyKey = Object.keys(value).length ? JSON.stringify(value).hashCode() : 0
          localStorage.removeItem(`${storageKey}.${itemId}`)
        }
      },
      clearForm (state, itemId) {
        const item = state.forms.find(i => i.id === itemId)
        // set initial values
        if (item && item.form) {
          item.form = getFormInitState(formFields)
          item.oldForm = getFormInitState(formFields)
        }
      },
      removeForm (state, itemId) {
        state.forms = state.forms.filter(i => i.id !== itemId)
      },
      resetForm (state, itemId) {
        // Store or update data.
        const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
        if (fetchedIdx >= 0) {
          const item = state.forms[fetchedIdx]
          // set initial values
          if (item && item.form && item.oldForm) {
            this.$log(`[STORE][${storageKey}][RESET_FORM]`, JSON.parse(JSON.stringify(item.oldForm)), fetchedIdx)
            item.form = JSON.parse(JSON.stringify(item.oldForm))
            item.dirty = {}
            item.dirtyKey = 0
            state.forms[fetchedIdx] = item
            localStorage.removeItem(`${storageKey}.${itemId}`)
          }
        }
      },
      applyForm (state, data) {
        // Store or update data.
        const fetchedIdx = state.forms.findIndex(i => i.id === data.itemId)
        this.$log(`[STORE][${storageKey}][APPLY_FORM]`, data, fetchedIdx)
        if (fetchedIdx >= 0) {
          const item = state.forms[fetchedIdx]
          // set initial values
          if (item && item.form && item.oldForm) {
            item.form = JSON.parse(JSON.stringify(data.formData))
            item.oldForm = JSON.parse(JSON.stringify(data.formData))
            item.dirty = {}
            item.dirtyKey = 0
            state.forms[fetchedIdx] = item
            localStorage.removeItem(`${storageKey}.${data.itemId}`)
          }
        }
      }
    },
    gfGetters: {
      pageTitle (state) {
        return state.pageTitle
      }
    }
  }
}
