const fields = {
  methods: {
    isSelectField (field) {
      return field &&
        field.type === 'select' &&
        this.filterData &&
        this.filterData[field.key]
    },
    isDateField (field) {
      return field &&
        field.type === 'date'
    },
    isDateRangeField (field) {
      return field &&
        field.type === 'date-range'
    },
    isNumRangeField (field) {
      return field &&
        field.type === 'num-range'
    },
    isCheckBoxField (field) {
      return field &&
        field.type === 'checkbox'
    }
  }
}

export default fields
