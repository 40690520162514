import { warn, error } from '~/mixins/helpers'

export function boChannelHandler ({ $auth, $store, $repo, responce }) {
  const handler = new BoChannelHandler($auth, $store, $repo, responce)

  switch (responce?.action) {
    // Listen for state
    case 'here':
      handler.here()
      break
    case 'joining':
      handler.joining()
      break
    case 'leaving':
      handler.leaving()
      break
    case 'error':
      handler.error()
      break
    // Listen for wisps
    case 'lockItem':
      handler.lockItem()
      break
    case 'unlockItem':
      handler.unlockItem()
      break
    case 'forceUnlockItem':
      handler.forceUnlockItem()
      break
    case 'reloadItem':
      handler.reloadItem()
      break
    default:
      warn('Unknown `bo` channel action', responce?.action)
      break
  }
}

class BoChannelHandler {
  auth = null
  store = null
  $repo = null
  responce = null

  constructor (auth, store, repo, responce) {
    this.auth = auth
    this.store = store
    this.$repo = repo
    this.responce = responce
    // get lock system methods
    this.isLockedByMeFn = this.store.getters['shared/isLockedByMe']
    this.isLockedByOtherFn = this.store.getters['shared/isLockedBy']
  }

  here () {
    this.store.dispatch('staffUser/initUsers', this.responce.users)
  }

  joining () {
    this.store.dispatch('staffUser/addUser', this.responce.user)
  }

  leaving () {
    if (parseInt(this.responce.user.id) !== parseInt(this.auth.user.userId)) {
      this.store.dispatch('staffUser/deleteUser', this.responce.user)
    }
  }

  error () {
    error('[BoChannelHandler][error]', this.responce.error)
  }

  lockItem () {
    const e = this.responce?.event
    const silent = this.responce?.silent || false
    const isLockedByMe = this.isLockedByMeFn(e.repo, e.id)
    const isLockedByOther = this.isLockedByOtherFn(e.repo, e.id)
    if (!isLockedByMe && !isLockedByOther) {
      // share locked items
      if (!silent) {
        this.store.dispatch(
          'shared/sendMessage',
          {
            cmd: 'lock',
            options: {
              action: 'setLocked',
              value: { formName: e.formName, repo: e.repo, id: parseInt(e.id), by: parseInt(e.by), email: e.email }
            }
          }
        )
      }
    }
  }

  unlockItem () {
    const e = this.responce?.event
    const silent = this.responce?.silent || false
    const isLockedByOther = this.isLockedByOtherFn(e.repo, e.id)
    if (isLockedByOther) {
      // share locked items
      if (!silent) {
        this.store.dispatch(
          'shared/sendMessage',
          {
            cmd: 'lock',
            options: {
              action: 'removeLocked',
              value: { formName: e.formName, repo: e.repo, id: parseInt(e.id), by: parseInt(e.by) }
            }
          }
        )
      }
    }
  }

  // Received wisp message from worker
  forceUnlockItem () {
    const e = this.responce?.event
    if (this.isLockedByMeFn(e.repo, e.id)) {
      // share locked items
      this.store.dispatch(
        'shared/sendMessage',
        {
          cmd: 'lock',
          options: {
            action: 'removeLockedMy',
            value: { formName: e.formName, repo: e.repo, id: parseInt(e.id), by: parseInt(e.by) }
          }
        }
      )
      this.store.dispatch(`forms/${e.formName}/resetForm`, parseInt(e.id))
      this.$repo.forceUnlockItemSuccess(e.repo, parseInt(e.id), parseInt(e.by))
    }
  }

  // TODO: use (shared/web) worker to do this
  reloadItem () {
    // reload only if current record is opened by user
    const e = this.responce?.event
    const call = this.store.state.repos.ds.find(i => i.name === `${e.repo}/show`)
    if (call?.args?.id === e.id) {
      this.store.dispatch(`forms/${e.formName}/resetForm`, parseInt(e.id))
      this.store.dispatch('repos/addCall', {
        repo: call.repo,
        method: 'show',
        name: call.name,
        idx: call.idx,
        ...call.args
      })
    }
  }
}
