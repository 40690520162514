const page = {
  props: {
    filter: {
      type: Array,
      required: false,
      default: () => ([])
    },
    order: {
      type: Object,
      required: false,
      default: () => ({
        by: 'created_at',
        dir: 'desc'
      })
    },
    limit: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  data () {
    return {
      searchQuery: '',
      tFilter: this.fiter,
      initialFilter: this.fiter,
      tOrder: this.order,
      routeQueryFetched: false,
      currentPage: parseInt(this.$route?.query?.page, 10) || 1,
      pageSize: parseInt(this.$route?.query?.limit, 10) || parseInt(this.limit, 10) || 10 //
    }
  },
  watch: {
    searchQuery () {
      this.updatePageQuery()
    },
    filter: {
      handler (n) {
        this.tFilter = n
        this.currentPage = 1 //
        this.updatePageQuery()
      },
      deep: true,
      immediate: true
    },
    order: {
      handler (n) { //
        if (
          n &&
          (typeof n.by !== 'undefined' && typeof n.dir !== 'undefined') &&
          (this.tOrder?.by !== n.by || this.tOrder?.dir !== n.dir)
        ) {
          // console.log('order watch', n.by, n.dir)
          this.tOrder = n
        }
        this.updatePageQuery()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updatePageQuery () {
      const query = {}
      const isDetailPage = this.$route.path.match(/^\/([\w]*)\/([\d]*)\/?/)
      // if page is detail - cancel query string
      if (!isDetailPage) {
        if (this.currentPage) {
          query.page = this.currentPage
        }
        if (this.pageSize) {
          query.limit = this.pageSize
        }
        if (this.tOrder) {
          query['order[by]'] = this.tOrder.by
          query['order[dir]'] = this.tOrder.dir
        }
        if (this.tFilter && this.tFilter.length) {
          this.tFilter = this.tFilter.filter(i => i.value !== '')
          for (const filter of this.tFilter) {
            if (typeof filter.value === 'object' && filter.value !== null) {
              for (const key of Object.keys(filter.value)) {
                query[`filter[${filter.column}][${key}]`] = filter.value[key]
              }
            } else if (filter.value !== null && filter.value !== '') {
              query[`filter[${filter.column}]`] = filter.value
            }
          }
        }
        if (this.searchQuery !== '') {
          query.q = this.searchQuery
        }
        this.$router.push({
          path: `${this.$route.path}`,
          query
        })
      }
    },
    parsePageQuery () {
      this.initialFilter = this.filter
      let hasFilter = false
      let hasSort = false
      let hasSearch = false
      const filter = []
      const order = {}
      let search = ''
      const query = this.$route.query
      Object.keys(query).forEach((k) => {
        const isPage = k.match(/^page$/)
        const isPageSize = k.match(/^limit$/)
        const isFilter = k.match(/^filter\[([\w.]*)\]\[?([\w.]*)?\]?/)
        const isOrder = k.match(/^order\[([\w.]*)\]/)
        const isSearch = k.match(/^q$/)
        if (isFilter) {
          hasFilter = true
          const column = isFilter[1]
          const subKey = isFilter[2]
          const idx = filter.findIndex(i => i.column === column)
          const initial = this.initialFilter.find(i => i.column === column)
          let value
          if (isFilter[2]) {
            value = idx >= 0 ? filter[idx]?.value : {}
            value[subKey] = query[k]
          } else {
            value = query[k]
          }
          if (idx >= 0) {
            filter[idx] = {
              column,
              value,
              permanent: !!(initial && initial.permanent)
            }
          } else {
            filter.push({
              column,
              value,
              permanent: !!(initial && initial.permanent)
            })
          }
        }
        if (isOrder) {
          hasSort = true
          order[isOrder[1]] = query[k]
        }
        if (isPage) {
          this.currentPage = parseInt(query.page, 10) || 1
        }
        if (isPageSize) {
          this.pageSize = parseInt(query.limit, 10) || 10
        }
        if (isSearch) {
          hasSearch = true
          search = unescape(decodeURI(query[k]))
        }
      })
      if (hasFilter) {
        this.$emit('on-filter', filter)
      }
      if (hasSort) {
        this.$emit('on-sort', order)
      }
      if (hasSearch) {
        this.onSearch(search)
      }
      this.routeQueryFetched = true
    }
  }
}

export default page
