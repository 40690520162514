import { warn } from '~/mixins/helpers'

export function chatChannelHandler ({ $auth, $store, responce }) {
  const handler = new ChatChannelHandler($auth, $store, responce)

  switch (responce?.action) {
    case 'UpdateUnread':
      handler.updateUnread()
      break
    case 'NewMsgReceived':
      handler.newMsgReceived()
      break
    case 'MessagesRead':
      handler.messagesRead()
      break
    case 'TalkStatusChanged':
      handler.talkStatusChanged()
      break
    case 'MessageDeleted':
      handler.messageDeleted()
      break
    default:
      warn('Unknown `chat` channel action', responce?.action)
      break
  }
}

class ChatChannelHandler {
  auth = null
  store = null
  responce = null

  constructor (auth, store, responce) {
    this.auth = auth
    this.store = store
    this.responce = responce
  }

  updateUnread () {
    //
  }

  newMsgReceived () {
    //
  }

  messagesRead () {
    //
  }

  talkStatusChanged () {
    //
  }

  messageDeleted () {
    //
  }
}
