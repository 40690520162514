import { log, warn } from '~/mixins/helpers'

export default function updateHandler ({ $auth, $store, $repo, responce }) {
  // log(`[UpdateHandler][${responce.cmd}][${responce.options.action}]`, responce.options.data)

  const data = responce.options.data

  switch (responce.options.action) {
    case 'dirtyItem':
      updateDirtyItem($store, data)
      break
    case 'applyForm':
      updateApplyForm($store, data)
      break
    default:
      warn('[UpdateHandler][unknown]', responce)
      break
  }
}

function updateDirtyItem ($store, data) {
  log('[UPD][DIRTY]', `${data?.options?.action}: ${data?.options?.data?.fieldName} => ${data?.options?.data?.value}`, data)
  const formName = (data.formKey.charAt(0).toLowerCase() + data.formKey.slice(1)).replace(/Form$/, '')
  const fetchedRecord = $store.state.forms[formName]?.forms?.find(i => i.id === data.id)
  if (fetchedRecord) {
    const fetchedForm = fetchedRecord?.form
    const fetchedValue = fetchedForm[`${data.fieldName}`]
    if (fetchedValue !== data.value) {
      $store.dispatch(`forms/${formName}/storeFormField`, {
        itemId: data.id,
        fieldName: data.fieldName,
        value: data.value,
        silent: true
      })
    }
  }
}

function updateApplyForm ($store, data) {
  log('[UPD][APPLY]', `${data.formKey}:${data?.id}`)
  const formName = data?.formKey
  const fetchedRecord = $store.state.forms[formName]?.forms?.find(i => i.id === data.id)
  if (fetchedRecord) {
    $store.dispatch(`forms/${formName}/applyForm`, { itemId: data.id, formData: data?.formData })
  }
}
